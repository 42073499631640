<template>
  <div class="custom-form-item">
    <template v-if="formType === 1">
      <a-input style="width: 300px" :value="value" @input="$emit('update:value', $event.target.value)" placeholder="请输入" />
      {{unit? unit:''}}
    </template>
    <template v-if="formType === 2">
      <a-select style="width: 300px" :value="value ? value : undefined" @change="handleSelectChange" placeholder="请选择"
        :getPopupContainer="node => node.parentNode || document.body"
      >
        <a-select-option :value="item" v-for="(item, index) in range" :key="item + index + ''">
          {{item}}
        </a-select-option>
      </a-select>
    </template>
    <template v-if="formType === 3">
      <a-radio-group :value="value" @change="$emit('update:value', $event.target.value)">
        <a-radio :value="item" v-for="item in range" :key="item">
          {{item}}
        </a-radio>
      </a-radio-group>
    </template>
    <template v-if="formType === 4">
      <a-checkbox-group :value="value" @change="onCheckboxChange">
        <a-checkbox :value="item" v-for="item in range" :key="item">
          {{item}}
        </a-checkbox>
      </a-checkbox-group>
    </template>

    <template v-if="formType === 5">
      <template v-for="tag in value">
        <a-tooltip v-if="tag.length > 10" :key="tag" :title="tag">
          <a-tag :key="tag" :closable="true" @close="() => handleClose(tag)">
            {{ `${tag.slice(0, 10)}...` }}
          </a-tag>
        </a-tooltip>
        <a-tag v-else :key="tag" :closable="true" @close="() => handleClose(tag)">
          {{ tag }}
        </a-tag>
      </template>
      <a-input
        v-if="inputVisible"
        ref="input"
        type="text"
        size="small"
        :style="{ width: '78px' }"
        :value="inputValue"
        @change="handleInputChange"
        @blur="handleInputConfirm"
        @keyup.enter="handleInputConfirm"
      />
      <!-- <a-icon type="plus-circle" /> -->
      <span v-else class="tag-add" @click="showInput">
        <a-icon type="plus" />
      </span>
    </template>

    <template v-if="formType === 6">
      <file-upload
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        list-type="picture"
        :default-file-list="fileList"
        class="upload-list-inline"
        @preview="handlePreview"
      >
        <a-button> <a-icon type="upload" /> 上传 </a-button>
        <span class="tip">建议上传3MB以内jpg、jpeg、png格式图片，最多上传9张图片</span>
      </file-upload>
    </template>

  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload.vue'
export default {
  name: 'CustomFormItem',
  props: {
    formType: Number,
    value:  [Number, String, Array],
    range: Array,
    tags: Array,
    unit: String
  },
  components: {
    FileUpload,
  },
  created() {
    console.log(this.formType)
    console.log(this.value)
  },
  data() {
    return {
      inputVisible: false,
      inputValue: '',

      fileList: [
        {
          uid: '-1',
          name: 'xxx.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
        {
          uid: '-2',
          name: 'yyy.png',
          status: 'done',
          url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
          thumbUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
      ],
    }
  },
  methods: {
    handleSelectChange(value) {
      console.log(value)
      this.$emit('update:value', value)
    },
    onCheckboxChange(value) {
      this.$emit('update:value', value)
    },

    handleClose(removedTag) {
      const tags = this.value.filter(tag => tag !== removedTag);
      console.log(tags);
      // this.value = tags;
      this.$emit('update:value', tags)
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    },

    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.inputValue;
      let tags = this.value;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      console.log(tags);
      this.$emit('update:value', tags)
      this.inputVisible = false
      this.inputValue = ''
      // Object.assign(this, {
      //   tags,
      //   inputVisible: false,
      //   inputValue: '',
      // });
    },
  }
}
</script>

<style lang="less" scoped>
.custom-form-item {
  .tag-add {
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    background: #0267ED;
  }
}
</style>